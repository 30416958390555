<template>
  <div class="register-access-redirect">
    <registerAccessRedirect />
  </div>
</template>

<script>
import RegisterAccessRedirect from '@/components/organisms/user/AppRegisterAccessRedirect'

export default {
  name: 'TheRegisterAccessRedirect',
  components: {
    'registerAccessRedirect': RegisterAccessRedirect,
  },
}
</script>
